(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/common/assets/javascripts/match-state.js') >= 0) return;  svs.modules.push('/components/sportinfo/common/assets/javascripts/match-state.js');
"use strict";


const {
  SportEventStatus
} = svs.components.sport.sportEventCommon;

const SportinfoMatchState = {
  NotStarted: 'NotStarted',
  Ongoing: 'Ongoing',
  Finished: 'Finished'
};

const getSportInfoMatchState = sportEventStatus => {
  switch (sportEventStatus) {
    case SportEventStatus.Undefined:
    case SportEventStatus.NotStarted:
    case SportEventStatus.AboutToStart:
    case SportEventStatus.Delayed:
    case SportEventStatus.Postponed:
      return SportinfoMatchState.NotStarted;
    case SportEventStatus.Live:
    case SportEventStatus.FirstPeriod:
    case SportEventStatus.SecondPeriod:
    case SportEventStatus.ThirdPeriod:
    case SportEventStatus.FirstHalf:
    case SportEventStatus.SecondHalf:
    case SportEventStatus.FirstQuarter:
    case SportEventStatus.SecondQuarter:
    case SportEventStatus.ThirdQuarter:
    case SportEventStatus.FourthQuarter:
    case SportEventStatus.FirstInning:
    case SportEventStatus.SecondInning:
    case SportEventStatus.ThirdInning:
    case SportEventStatus.FourthInning:
    case SportEventStatus.FifthInning:
    case SportEventStatus.SixthInning:
    case SportEventStatus.SeventhInning:
    case SportEventStatus.EighthInning:
    case SportEventStatus.NinthInning:
    case SportEventStatus.Overtime:
    case SportEventStatus.FirstExtraPeriod:
    case SportEventStatus.SecondExtraPeriod:
    case SportEventStatus.ThirdExtraPeriod:
    case SportEventStatus.AwaitingPenalties:
    case SportEventStatus.Penalties:
    case SportEventStatus.Pause:
    case SportEventStatus.AwaitingExtratime:
    case SportEventStatus.Halftime:
    case SportEventStatus.Extratime:
    case SportEventStatus.Interrupted:
    case SportEventStatus.FallbackFourthPeriod:
    case SportEventStatus.FallbackFifthPeriod:
    case SportEventStatus.FallbackFirstSet:
    case SportEventStatus.FallbackSecondSet:
    case SportEventStatus.FallbackThirdSet:
    case SportEventStatus.FallbackFourthSet:
    case SportEventStatus.FallbackFifthSet:
    case SportEventStatus.FallbackExtraTimeHalftime:
    case SportEventStatus.FallbackInFirstBreak:
    case SportEventStatus.FallbackInSecondBreak:
    case SportEventStatus.FallbackInThirdBreak:
    case SportEventStatus.FallbackGoldenSet:
    case SportEventStatus.FallbackSixthSet:
    case SportEventStatus.FallbackSeventhSet:
    case SportEventStatus.FallbackFrame1:
    case SportEventStatus.FallbackFrame2:
    case SportEventStatus.FallbackFrame3:
    case SportEventStatus.FallbackFrame4:
    case SportEventStatus.FallbackFrame5:
    case SportEventStatus.FallbackFrame6:
    case SportEventStatus.FallbackFrame7:
    case SportEventStatus.FallbackFrame8:
    case SportEventStatus.FallbackFrame9:
    case SportEventStatus.FallbackFrame10:
    case SportEventStatus.FallbackFrame11:
    case SportEventStatus.FallbackFrame12:
    case SportEventStatus.FallbackFrame13:
    case SportEventStatus.FallbackFrame14:
    case SportEventStatus.FallbackFrame15:
    case SportEventStatus.FallbackFrame16:
    case SportEventStatus.FallbackFrame17:
    case SportEventStatus.FallbackFrame18:
    case SportEventStatus.FallbackFrame19:
    case SportEventStatus.FallbackFrame20:
    case SportEventStatus.FallbackFrame21:
    case SportEventStatus.FallbackFrame22:
    case SportEventStatus.FallbackFrame23:
    case SportEventStatus.FallbackFrame24:
    case SportEventStatus.FallbackFrame25:
    case SportEventStatus.FallbackFrame26:
    case SportEventStatus.FallbackFrame27:
    case SportEventStatus.FallbackFrame28:
    case SportEventStatus.FallbackFrame29:
    case SportEventStatus.FallbackFrame30:
    case SportEventStatus.FallbackFrame31:
    case SportEventStatus.FallbackFrame32:
    case SportEventStatus.FallbackFrame33:
    case SportEventStatus.FallbackFrame34:
    case SportEventStatus.FallbackFrame35:
    case SportEventStatus.FallbackFrame36:
    case SportEventStatus.FallbackFrame37:
    case SportEventStatus.FallbackAwaitingSuddenDeath:
    case SportEventStatus.FallbackInSuddenDeath:
    case SportEventStatus.FallbackFirstInningsHomeTeam:
    case SportEventStatus.FallbackFirstInningsAwayTeam:
    case SportEventStatus.FallbackSecondInningsHomeTeam:
    case SportEventStatus.FallbackSecondInningsAwayTeam:
    case SportEventStatus.FallbackAwaitingSuperOver:
    case SportEventStatus.FallbackInSuperOverHomeTeam:
    case SportEventStatus.FallbackInSuperOverAwayTeam:
    case SportEventStatus.FallbackLunchBreak:
    case SportEventStatus.FallbackTeaBreak:
    case SportEventStatus.FallbackStumps:
    case SportEventStatus.FallbackSuspended:
      return SportinfoMatchState.Ongoing;
    case SportEventStatus.Abandoned:
    case SportEventStatus.Cancelled:
    case SportEventStatus.Ended:
    case SportEventStatus.Closed:
    case SportEventStatus.Fulltime:
    case SportEventStatus.EndedAfterExtratime:
    case SportEventStatus.EndedAfterPenalties:
    case SportEventStatus.FallbackWalkover:
    case SportEventStatus.FallbackParticipantRetired:
    case SportEventStatus.FallbackEndedAfterSuddenDeath:
    case SportEventStatus.FallbackEndedAfterSuperOver:
    case SportEventStatus.FallbackDefaulted:
    case SportEventStatus.FallbackWalkoverHomeTeamWon:
    case SportEventStatus.FallbackWalkoverAwayTeamWon:
    case SportEventStatus.FallbackParticipantRetiredHomeTeamWon:
    case SportEventStatus.FallbackParticipantRetiredAwayTeamWon:
    case SportEventStatus.FallbackDefaultedHomeTeamWon:
    case SportEventStatus.FallbackDefaultedAwayTeamWon:
      return SportinfoMatchState.Finished;
    default:
      return SportinfoMatchState.NotStarted;
  }
};

const getIsNotStarted = sportEventStatus => getSportInfoMatchState(sportEventStatus) === SportinfoMatchState.NotStarted;
const getIsOngoing = sportEventStatus => getSportInfoMatchState(sportEventStatus) === SportinfoMatchState.Ongoing;
const getIsFinished = sportEventStatus => getSportInfoMatchState(sportEventStatus) === SportinfoMatchState.Finished;

const getIsOvertime = sportEventStatus => {
  switch (sportEventStatus) {
    case SportEventStatus.Overtime:
    case SportEventStatus.FirstExtraPeriod:
    case SportEventStatus.SecondExtraPeriod:
    case SportEventStatus.ThirdExtraPeriod:
    case SportEventStatus.AwaitingPenalties:
    case SportEventStatus.Penalties:
    case SportEventStatus.AwaitingExtratime:
    case SportEventStatus.Extratime:
    case SportEventStatus.FallbackExtraTimeHalftime:
    case SportEventStatus.FallbackAwaitingSuddenDeath:
    case SportEventStatus.FallbackInSuddenDeath:
    case SportEventStatus.FallbackAwaitingSuperOver:
    case SportEventStatus.FallbackInSuperOverHomeTeam:
    case SportEventStatus.FallbackInSuperOverAwayTeam:
      return true;
    default:
      return false;
  }
};
const getIsFinishedAfterOvertime = sportEventStatus => {
  switch (sportEventStatus) {
    case SportEventStatus.EndedAfterExtratime:
    case SportEventStatus.EndedAfterPenalties:
    case SportEventStatus.FallbackEndedAfterSuddenDeath:
    case SportEventStatus.FallbackEndedAfterSuperOver:
      return true;
    default:
      return false;
  }
};
const getIsFinishedNotCancelled = sportEventStatus => getIsFinished(sportEventStatus) && sportEventStatus !== SportEventStatus.Abandoned && sportEventStatus !== SportEventStatus.Cancelled;
const getIsInPenalties = sportEventStatus => {
  switch (sportEventStatus) {
    case SportEventStatus.Penalties:
    case SportEventStatus.EndedAfterPenalties:
      return true;
    default:
      return false;
  }
};
const getIsCancelled = sportEventStatus => sportEventStatus === SportEventStatus.Cancelled;
const getIsOvertimeOrPenalties = sportEventStatus => getIsOvertime(sportEventStatus) || getIsFinishedAfterOvertime(sportEventStatus);
const getIsPostponedOrDelayed = sportEventStatus => {
  switch (sportEventStatus) {
    case SportEventStatus.Postponed:
    case SportEventStatus.Delayed:
      return true;
    default:
      return false;
  }
};
const toExport = {
  SportinfoMatchState,
  getSportInfoMatchState,
  getIsNotStarted,
  getIsOngoing,
  getIsFinished,
  getIsFinishedAfterOvertime,
  getIsOvertime,
  getIsFinishedNotCancelled,
  getIsInPenalties,
  getIsCancelled,
  getIsOvertimeOrPenalties,
  getIsPostponedOrDelayed
};
setGlobal('svs.components.sportinfo.common.matchState', toExport);

 })(window);